/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useEffect, useState } from "react";

type CardProps = {
  title: string;
  imageUrl: string;
  children: any;
  link: string;
  linkText: string;
};

export function Card(props: CardProps) {
  const [windowIsLarge, setMatches] = useState(undefined);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 700px)");
    setMatches(mediaQuery.matches);
    const callback = (e) => setMatches(e.matches)
    mediaQuery.onchange = callback;
  });

  const cardStyling: React.CSSProperties = {
    width: windowIsLarge ? "60%" : "100%",
    borderWidth: "1px",
    borderStyle: "solid",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "4px",
    cursor: "pointer",
    display: "block",
    textDecoration: "none",
  };

  return (
    <a
      sx={{
        mb: [5, 5, 5],
        p: [2, 3, 3],
        borderColor: "secondary",
      }}
      style={cardStyling}
      href={props.link}
    >
      <img width="100%" src={props.imageUrl} alt={props.title}></img>
      <h2 sx={{ color: "text" }}>{props.title}</h2>
      <p sx={{ fontSize: [1, 2, 2], mt: 2, color: "text" }}>{props.children}</p>
      <p sx={{ fontSize: [1, 1, 1], mt: 2, color: "text" }}>
        {props.linkText}
      </p>
    </a>
  );
}
